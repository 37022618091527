html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.inline-block {
  display: inline-block;
}
.page-header {
  display: flex;
}
.back-link {
  margin: 1rem 1rem 0.84rem 0;
}
.back-link .material-icons {
  font-size: 3rem;
}
.header-with-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
  .modal {
    width: 98%;
  }
  .scrollable {
    overflow-x: scroll;
  }
}
.btn {
  margin-right: 0.5rem;
}
.btn:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 600px) {
  .btn.btn-full-on-small {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .btn.btn-full-on-small {
    width: 100%;
    margin-right: 0;
  }
}
network-status {
  height: 2rem;
  margin: 1rem 0.5rem 0;
  width: 2.2rem;
  text-align: center;
  float: right;
}
network-status i,
nav .nav-wrapper network-status i {
  height: 2rem;
  line-height: 2rem;
}
nav ul button {
  transition: background-color 0.3s;
  font-size: 1rem;
  line-height: 64px;
  color: #fff;
  display: block;
  padding: 0 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
nav ul button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidenav li form button {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 48px;
  padding: 0 32px;
  width: 100%;
  text-align: left;
}
.sidenav li form button i {
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  color: rgba(0, 0, 0, 0.54);
}
.header-title {
  position: absolute;
  font-size: 1.5rem;
  padding: 0;
  white-space: nowrap;
  left: 50px;
  right: 40px;
}
.navbar-link {
  float: left;
  position: relative;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
}
.collection .collection-item.avatar-minimum {
  min-height: 62px;
}
.collection .collection-item.collection-item-flex {
  display: flex;
}
.collection .collection-item.collection-item-flex .datetime-container {
  margin-right: 1rem;
}
.collection .collection-item.collection-item-flex .secondary-content {
  margin-left: auto;
}
.collection .collection-item.selected {
  background-color: #eee;
}
@keyframes blockTextShine {
  0% {
    transform: translateX(-468px);
  }
  100% {
    transform: translateX(468px);
  }
}
.indicator-load .card-title {
  position: relative;
  background-color: #f2f2f2;
  width: 76px;
  height: 32px;
  overflow: hidden;
}
.indicator-load .card-action-link {
  position: relative;
  background-color: #f2f2f2;
  width: 50%;
  height: 18px;
  overflow: hidden;
}
.indicator-load .skeleton-line-1 {
  width: 100%;
}
.indicator-load .skeleton-line-2 {
  width: 75%;
}
.indicator-load .skeleton-line-3 {
  width: 50%;
}
.indicator-load [class^="skeleton-line-"] {
  position: relative;
  background-color: #f2f2f2;
  height: 10px;
  overflow: hidden;
}
.indicator-load [class^="skeleton-line-"]:not(:last-of-type) {
  margin-bottom: 4px;
}
.indicator-load .card-title::after,
.indicator-load .card-action-link::after,
.indicator-load [class^="skeleton-line-"]::after {
  content: ' ';
  display: block;
  animation: blockTextShine 1s linear infinite forwards;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(to right, #f2f2f2 0%, #fafafa 20%, #f2f2f2 40%, #f2f2f2 100%);
  height: 10px;
}
.datetime-container {
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1rem;
}
.process-active-component {
  font-size: 80%;
  margin-top: 0.5rem;
}
.process-chart {
  background-color: #f2f2f2;
  min-height: 400px;
}
.relative {
  position: relative;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.content {
  min-height: calc(100vh - 84px - 50px);
}
.view-field {
  position: relative;
  margin-top: 2rem;
}
.view-field label {
  color: #9e9e9e;
  position: absolute;
  top: -1.2rem;
  left: 0.75rem;
  font-size: 0.8rem;
  cursor: text;
  transition: 0.2s ease-out;
}
.preloader-container {
  position: fixed;
  width: 100%;
  height: 4px;
}
.progress {
  margin: 0;
}
.collection-item-subtitle {
  font-size: 0.8em;
  font-style: italic;
}
.dashed {
  border-bottom: 1px dashed;
}
@media only screen and (min-width: 0) {
  h1 {
    font-size: 2.1rem;
    margin: 1rem 0 0.84rem 0;
  }
  h2 {
    font-size: 1.78rem;
    margin: 0.89rem 0 0.712rem 0;
  }
  h3 {
    font-size: 1.46rem;
    margin: 0.73rem 0 0.584rem 0;
  }
  h4 {
    font-size: 1.2rem;
    margin: 0.57rem 0 0.406rem 0;
  }
  h5 {
    font-size: 1.1rem;
    margin: 0.41rem 0 0.333rem 0;
  }
  h6 {
    font-size: 1rem;
    margin: 0.25rem 0 0.2rem 0;
  }
}
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 2.5em;
  }
}
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 3em;
  }
}
